import React, { useContext } from 'react'
import { makeStyles } from '@material-ui/styles'
import { userContext } from '@context/CurrentUser'
import TableReferral from './TableReferral'
import TableWithdraw from './TableWithdraw'

const useStyles = makeStyles(() => ({
  root: {
    // padding: '0 2rem',
  },
}))

const Body = (props) => {
  const classes = useStyles()
  const { token } = useContext(userContext)

  return (
    <div className={classes.root}>
      <TableReferral token={token} {...props} />
      <TableWithdraw token={token} {...props} />
    </div>
  )
}

export default Body
