import React from 'react'
import { Route, Switch } from 'react-router-dom'
import Login from '@containers/pages/auth/Login'
import DashboardLayout from '@containers/layout/DashboardLayout'
import PrivateRoute from '@components/PrivateRoutes'
import * as page from '@containers/pages'

const Routes = () => (
  <Switch>
    <Route exact path="/" component={Login} />
    <Route path="/login" component={Login} />
    <Route path="/register" component={page.register} />
    <Route path="/forgot_password" component={page.forgotPassword} />
    <Route path="/reset_password" component={page.resetPassword} />
    <Route
      render={(props) => (
        <DashboardLayout {...props}>
          <Switch>
            <PrivateRoute path="/dashboard" component={page.dashboard} />
          </Switch>
        </DashboardLayout>
      )}
    />
    <Route component={page.notFound} />
  </Switch>
)

export default Routes
