import React from 'react'
import { Link, Button } from '@material-ui/core'
import CenterLayout from '@containers/layout/CenterLayout'

export default function Landing({ setPage }) {
  const squidsAffiliateLink = <Link href="#!" component="button">Squids Affiliate</Link>
  return (
    <CenterLayout
      title="Squids Affiliate - Atur Ulang Kata Sandi"
      pageTitle="Atur Ulang Kata Sandi"
    >
      <p>Sepertinya Anda lupa kata sandi untuk {squidsAffiliateLink}. Jika ini benar, klik di bawah ini untuk mengatur ulang kata sandi Anda.</p>
      <Button
        style={{ margin: '1rem 0' }}
        color="primary"
        variant="contained"
        onClick={() => setPage('form')}
      >
        Atur Ulang Password Saya
      </Button>
      <p>Jika Anda tidak lupa kata sandi, Anda dapat mengabaikan email ini dengan aman.</p>
    </CenterLayout>
  )
}
