import React from 'react'
import { Link } from '@material-ui/core'
import CenterLayout from '@containers/layout/CenterLayout'

export default function Result({ setPage }) {
  return (
    <CenterLayout
      title="Squids Affiliate - Permintaan Menagatur Ulang Password"
      pageTitle="Email berhasil dikirim!"
    >
      <p>Kami telah mengirimkan tautan untuk mengatur ulang kata sandi. Mohon periksa email Anda.</p>
      <p>
        Tidak menerima Email?&nbsp;
        <Link
          href="#!"
          onClick={() => setPage('form')}
          component="button"
        >
          Kirim ulang
        </Link>
      </p>
    </CenterLayout>
  )
}
