import axios from 'axios'
import { AFFILIATE_API } from '@utils/Environment'
import Cookies from 'js-cookie'

const withdrawApi = `${AFFILIATE_API}/api/v1/affiliate_withdraws`
export const token = Cookies.get('affiliate_app_token')

export default {
  async request(params) {
    try {
      const response = await axios.post(
        withdrawApi,
        {
          affiliate_withdraw: {
            amount: parseInt(params.amount, 10),
            bank_id: params.bank?.id,
            bank_account: params.bank_account,
            account_name: params.account_name,
            bank_name: params.bank?.name,
            status: 'Pending',
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      return response.data
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async index(currentToken, page, searchValue, dates) {
    let params = { page }
    if (searchValue) {
      params = { bank_name: searchValue, ...params }
    }
    if (dates?.startDate) {
      params = { start_date: dates?.startDate, ...params }
    }
    if (dates?.endDate) {
      params = { end_date: dates?.endDate, ...params }
    }
    try {
      const response = await axios.get(withdrawApi, {
        params,
        headers: {
          Authorization: `Bearer ${token || currentToken}`,
        },
      })
      return response.data
    } catch (error) {
      return false
    }
  },
  async listBank() {
    try {
      const response = await axios.get(`${AFFILIATE_API}/api/v1/banks`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      return response.data
    } catch (error) {
      return false
    }
  },
}
