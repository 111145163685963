import axios from 'axios'
import { AFFILIATE_API } from '@utils/Environment'
import { token } from '@utils/Authorization'

const transactionApi = `${AFFILIATE_API}/api/v1/affiliate_transactions`

export default {
  async index(currentToken, page, searchValue, dates) {
    let params = { page }
    if (searchValue) {
      params = { name_or_store: searchValue, ...params }
    }
    if (dates?.startDate) {
      params = { start_date: dates?.startDate, ...params }
    }
    if (dates?.endDate) {
      params = { end_date: dates?.endDate, ...params }
    }
    try {
      const response = await axios.get(transactionApi, {
        params,
        headers: {
          Authorization: `Bearer ${token || currentToken}`,
        },
      })
      return response.data
    } catch (error) {
      return false
    }
  },
}
