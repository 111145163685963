import { createMuiTheme } from '@material-ui/core'

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#07689F',
      contrastText: '#C4C4C4',
      textGrey: '#676767',
    },
    secondary: {
      main: '#1A253B',
    },
  },
  typography: {
    fontFamily: "'Poppins', sans-serif",
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: 'capitalize',
        fontWeight: 600,
        color: '#fff',
        borderRadius: '5px',
        boxShadow: 'none !important',
        padding: '0.625rem 1rem',
        fontSize: '1rem',
      },
      containedPrimary: {
        color: '#fff',
      },
    },
    MuiLink: {
      button: {
        fontWeight: 600,
        fontSize: '1rem',
      },
    },
    MuiInput: {
      underline: {
        '&:before': { // underline color when textfield is inactive
          borderBottom: 'solid 1px #c4c4c4',
        },
      },
      root: {
        '&::after': {
          display: 'none',
        },
        '&::before': {
          display: 'none',
        },
      },
    },
    MuiInputBase: {
      input: {
        padding: '0 0 0.8125rem',
        lineHeight: '25px',
      },
    },
    MuiInputAdornment: {
      positionStart: {
        marginRight: '0.625rem',
        paddingBottom: '0.8125rem',
      },
    },
    MuiBreadcrumbs: {
      root: {
        color: '#fff',
      },
      li: {
        '& a': {
          color: '#fff',
          '&:hover': {
            textDecoration: 'none',
          },
        },
        '&:hover': {
          opacity: 0.8,
        },
      },
    },
    MuiTableCell: {
      head: {
        fontWeight: 600,
        color: 'rgba(7,104,159,0.75)',
        lineHeight: 'normal',
        padding: '0.625rem 0.875rem',
        borderBottom: '1px solid #C4C4C4',
      },
      body: {
        color: '#1A253B',
        padding: '0.625rem 0.875rem',
        borderBottom: '0.5px solid #C4C4C4',
      },
    },
    MuiOutlinedInput: {
      input: {
        padding: '0.625rem',
      },
      notchedOutline: {
        border: 0,
      },
    },
    MuiPaginationItem: {
      page: {
        '&.Mui-selected': {
          color: '#07689F',
          backgroundColor: 'rgba(7, 104, 159, 0.3)',
          fontWeight: 600,
        },
      },
    },
  },
})

export default theme
