import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '16px 1px 16px 1px',
    display: 'flex',
    flexDirection: 'column',
    height: 70,
    position: 'relative',
    minWidth: 120,
  },
  field: {
    backgroundColor: '#F6F9FB',
    borderRadius: 5,
    border: 'solid 0.5px #cccccc',
    padding: theme.spacing(1),
    '&.Mui-focused': {
      border: `solid 0.5px ${theme.palette.primary.main}`,
    },
    '&.MuiInput-underline': {
      borderBottom: 'none',
    },
  },
  error: {
    border: 'solid 0.5px red',
  },
  errorMessage: {
    color: 'red',
  },
  inputLabel: {
    marginBottom: theme.spacing(0.5),
    fontSize: 12,
  },
  hide: {
    display: 'none',
  },
  autocomplete: {
    backgroundColor: '#F6F9FB',
    borderRadius: 5,
  },
  autoCompleteRoot: {
    marginBottom: theme.spacing(4),
    width: '100%',
  },
  productQty: {
    height: 56,
    width: 66,
    textAlign: 'center',
    fontSize: 14,
  },
  qtyWrapper: {
    margin: '16px 1px 16px 1px',
    display: 'flex',
    flexDirection: 'column',
    height: 70,
    width: 100,
    position: 'relative',
  },
  rupias: {
    height: 48,
    borderRadius: 5,
    outlineRadius: 5,
    '&:focus': {
      border: `solid 1.5px ${theme.palette.primary.main}`,
      outline: 'none',
    },
  },
}))

export default useStyles
