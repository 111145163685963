import React, {
  Fragment,
  useState,
  useEffect,
  useContext,
} from 'react'
import { useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { makeStyles } from '@material-ui/styles'
import {
  Button, CircularProgress, InputAdornment,
} from '@material-ui/core'
import Modal from '@components/Modal'
import Text from '@components/RHFFields/Text'
import { userContext } from '@context/CurrentUser'
import UserApi from '@api/User'
import { ReactComponent as Profile } from '@assets/images/svg/profile.svg'
import { ReactComponent as NameIcon } from '@assets/images/svg/name-primary.svg'
import { ReactComponent as EmailIcon } from '@assets/images/svg/email-primary.svg'
import { ReactComponent as PhoneIcon } from '@assets/images/svg/agenda-primary.svg'
import { ReactComponent as CodeICon } from '@assets/images/svg/code-primary.svg'
import { ReactComponent as BankIcon } from '@assets/images/svg/bank-primary.svg'
import { ReactComponent as SafeBoxIcon } from '@assets/images/svg/safe-box-primary.svg'
import { ReactComponent as CalculationIcon } from '@assets/images/svg/calculation-primary.svg'
import { ReactComponent as SuccessIcon } from '@assets/images/svg/success.svg'

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    alignItems: 'flex-end',
    flexWrap: 'wrap',
    width: 'calc(100% + 1rem)',
    margin: '0 -1rem',
    '& > div': {
      flex: '0 0 calc(50% - 2rem)',
      margin: '1.5rem 1rem 0',
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  headerLeft: {
    display: 'flex',
    alignItems: 'center',
    '& h1': {
      fontSize: '1.25rem',
      color: theme.palette.secondary.main,
    },
    '& svg': {
      marginRight: '1rem',
    },
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative',
    top: '-1rem',
  },
  button: {
    width: 'calc(50% - 1rem)',
  },
  result: {
    textAlign: 'center',
    fontWeight: 600,
    width: 400,
    '& p': {
      marginBottom: 0,
    },
  },
}))

const resolver = yupResolver(yup.object().shape({
  email: yup.string().email('Email tidak valid').required('Tidak boleh kosong'),
  name: yup.string().required('Tidak boleh kosong'),
  phone_number: yup.string().required('Tidak boleh kosong'),
  code: yup.string()
    .required('Tidak boleh kosong')
    .min(5, 'Kode Referral minimal 5 karakter'),
}))

export default function EditProfile({ setOpenProfile }) {
  const classes = useStyles()
  const history = useHistory()
  const { user, fetchCurrentUser } = useContext(userContext)
  const {
    handleSubmit, control, errors, register,
  } = useForm({ resolver })
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState('form')
  const [profile, setProfile] = useState()

  useEffect(() => {
    UserApi.getProfile(user?.id)
      .then((res) => {
        if (res) {
          setProfile(res.data)
        } else {
          history.push('/')
        }
      })
  }, [])

  const onSubmit = (value) => {
    setLoading(true)
    UserApi.updateProfile(value)
      .then((res) => {
        if (res) {
          setPage('result')
          setLoading(false)
          fetchCurrentUser()
          setTimeout(() => {
            setOpenProfile(false)
          }, 1000)
        }
      })
  }

  return (
    <Fragment>
      {profile && (
        <Modal>
          {(page === 'form') && (
            <Fragment>
              <div className={classes.header}>
                <div className={classes.headerLeft}>
                  <Profile />
                  <h1>Edit Profile</h1>
                </div>
              </div>
              <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
                <input name="id" type="hidden" ref={register} defaultValue={profile?.id} />
                <div>
                  <Text
                    name="email"
                    label="Email"
                    defaultValue={profile?.email}
                    control={control}
                    register={register}
                    errors={errors}
                    InputProps={{
                      disabled: loading,
                      startAdornment: <InputAdornment position="start"><EmailIcon /></InputAdornment>,
                      fullWidth: true,
                    }}
                  />
                </div>
                <div>
                  <Text
                    name="bank_name"
                    label="Nama Bank"
                    defaultValue={profile?.bank_name}
                    control={control}
                    register={register}
                    errors={errors}
                    InputProps={{
                      disabled: loading,
                      startAdornment: <InputAdornment position="start"><BankIcon /></InputAdornment>,
                      fullWidth: true,
                    }}
                  />
                </div>
                <div>
                  <Text
                    name="name"
                    label="Nama Lengkap"
                    defaultValue={profile?.name}
                    control={control}
                    register={register}
                    errors={errors}
                    InputProps={{
                      disabled: loading,
                      startAdornment: <InputAdornment position="start"><NameIcon /></InputAdornment>,
                      fullWidth: true,
                    }}
                  />
                </div>
                <div>
                  <Text
                    name="bank_account"
                    label="Nomor Rekening"
                    defaultValue={profile?.bank_account}
                    control={control}
                    register={register}
                    errors={errors}
                    InputProps={{
                      disabled: loading,
                      startAdornment: <InputAdornment position="start"><SafeBoxIcon /></InputAdornment>,
                      fullWidth: true,
                    }}
                  />
                </div>
                <div>
                  <Text
                    name="phone_number"
                    label="Nomor Telp"
                    defaultValue={profile?.phone_number}
                    control={control}
                    register={register}
                    errors={errors}
                    InputProps={{
                      disabled: loading,
                      startAdornment: <InputAdornment position="start"><PhoneIcon /></InputAdornment>,
                      fullWidth: true,
                    }}
                  />
                </div>
                <div>
                  <Text
                    name="account_name"
                    label="Nama Pemilik Rekening"
                    defaultValue={profile?.account_name}
                    control={control}
                    register={register}
                    errors={errors}
                    InputProps={{
                      disabled: loading,
                      startAdornment: <InputAdornment position="start"><CalculationIcon /></InputAdornment>,
                      fullWidth: true,
                    }}
                  />
                </div>
                <div>
                  <Text
                    name="code"
                    label="Kode Referral"
                    defaultValue={profile?.code}
                    control={control}
                    register={register}
                    errors={errors}
                    InputProps={{
                      disabled: loading,
                      startAdornment: <InputAdornment position="start"><CodeICon /></InputAdornment>,
                      fullWidth: true,
                    }}
                  />
                </div>
                <div className={classes.actions}>
                  <Button
                    className={classes.button}
                    variant="contained"
                    onClick={() => setOpenProfile(false)}
                  >
                    Batal
                  </Button>
                  <Button
                    className={classes.button}
                    color="primary"
                    variant="contained"
                    type="submit"
                    disabled={loading}
                  >
                    {loading ? <CircularProgress size={24} color="secondary" /> : 'Kirim'}
                  </Button>
                </div>
              </form>
            </Fragment>
          )}
          {page === 'result' && (
            <div className={classes.result}>
              <SuccessIcon />
              <p>Profil berhasil diubah.</p>
            </div>
          )}
        </Modal>
      )}
    </Fragment>
  )
}
