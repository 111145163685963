import React, { Fragment, useState } from 'react'
import AuthContainer from '@containers/layout/AuthContainer'
import Background from '@assets/images/webp/background-register.webp'
import { makeStyles } from '@material-ui/styles'
import { Link } from 'react-router-dom'
import { Typography } from '@material-ui/core'
import RegisterForm from './RegisterForm'
import Result from './Result'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  link: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    alignSelf: 'flex-start',
    justifySelf: 'flex-start',
  },
}))

const Register = () => {
  const classes = useStyles()
  const [page, setPage] = useState('form')
  const [values, setValues] = useState({})

  return (
    <Fragment>
      {page === 'form' && (
        <AuthContainer
          title="Squids Affiliate - Register"
          childrenRight={<img src={Background} alt="man" />}
        >
          <div className={classes.root}>
            <RegisterForm setPage={setPage} setValues={setValues} />
            <Typography
              style={{
                marginTop: 20,
                fontSize: 12,
              }}
              color="textSecondary"
            >
              Anda Sudah Memiliki Akun? <Link to="/login" className={classes.link}>Masuk</Link>
            </Typography>
          </div>
        </AuthContainer>
      )}
      {page === 'result' && (
        <Result values={values} />
      )}
    </Fragment>
  )
}
export default Register
