import React from 'react'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(() => ({
  root: {
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 9999,
    width: '100%',
    height: '100vh',
    background: 'rgba(0, 0, 0, 0.3)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '2rem',
  },
  box: {
    background: '#fff',
    borderRadius: '20px',
    padding: '2rem',
    maxWidth: 856,
  },
}))

export default function Modal({ children }) {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <div className={classes.box}>
        {children}
      </div>
    </div>
  )
}
