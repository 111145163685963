/* eslint-disable react/prop-types */
/* eslint-disable react/require-default-props */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Autocomplete } from '@material-ui/lab'
import { Controller } from 'react-hook-form'
import { FormHelperText, InputLabel, TextField } from '@material-ui/core'
import clsx from 'clsx'
import useStyles from './styles-jss'

const InputAutocomplete = (props) => {
  const {
    name,
    initialValue,
    label,
    getOptionSelected,
    getOptionLabel,
    disabledField,
    options,
    register,
    setValue,
    errors,
    customHelperText,
    defaultValue,
  } = props
  const [open, setOpen] = React.useState(false)
  const [selectedValue, setSelectedValue] = useState({
    id: '', name: '', formated_label: '', formatted_label: '',
  })
  const classes = useStyles()

  const renderErrorMessage = () => (
    !!errors[name] && errors[name].message
  )

  const renderInputLabel = () => (
    label && (
    <InputLabel className={classes.inputLabel}>
      {label}
    </InputLabel>
    )
  )

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    if (initialValue !== undefined) {
      setSelectedValue(initialValue)
    }
  }, [])
  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    if (defaultValue) {
      setSelectedValue(defaultValue)
    }
  }, [defaultValue])

  return (
    <div className={clsx(classes.root, classes.autoCompleteRoot)}>
      {renderInputLabel()}
      <Autocomplete
        ref={register}
        name={name}
        open={open}
        value={selectedValue}
        onOpen={() => {
          setOpen(true)
        }}
        onClose={() => {
          setOpen(false)
        }}
        options={options}
        getOptionSelected={getOptionSelected}
        getOptionLabel={getOptionLabel}
        disabled={disabledField}
        onChange={(e, value, reason) => {
          if (reason === 'select-option') {
            setValue(name, value, { shouldValidate: true })
            setSelectedValue(value)
          } else if (reason === 'clear') {
            setValue(name, value, { shouldValidate: true })
            setSelectedValue({
              name: '', id: '', formated_label: '', formatted_label: '',
            })
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={`Ketik Untuk Cari ${label}...`}
            className={classes.autocomplete}
            variant="outlined"
          />
        )}
      />
      {
        customHelperText && (
          <InputLabel className={classes.inputLabel}>
            {customHelperText}
          </InputLabel>
        )
      }
      <FormHelperText className={classes.errorMessage}>{renderErrorMessage()}</FormHelperText>
    </div>
  )
}

InputAutocomplete.defaultProps = {
  errors: {},
  message: '',
  label: '',
}

InputAutocomplete.propTypes = {
  name: PropTypes.string.isRequired,
  register: PropTypes.func.isRequired,
  initialValue: PropTypes.object,
  errors: PropTypes.object,
  message: PropTypes.string,
  label: PropTypes.any,
  setValue: PropTypes.func.isRequired,
  getOptionSelected: PropTypes.func,
  getOptionLabel: PropTypes.func,
}

const SelecFromApi = (props) => {
  const { control, name, defaultValues } = props
  return (
    <Controller
      name={name}
      control={control}
      render={(onChange, onBlur) => (
        <InputAutocomplete
          onChange={onChange}
          onBlur={onBlur}
          initialValue={defaultValues[name] || null}
          {...props}
        />
      )}
    />
  )
}

SelecFromApi.defaultProps = {
  defaultValues: '',
}

SelecFromApi.propTypes = {
  control: PropTypes.any.isRequired,
  name: PropTypes.any.isRequired,
  defaultValues: PropTypes.any,
}

export default SelecFromApi
