import React, { useState, useContext } from 'react'
import {
  Breadcrumbs,
  Button,
  Link,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import { ReactComponent as HomeIcon } from '@assets/images/svg/home.svg'
import { ReactComponent as WithdrawIcon } from '@assets/images/svg/withdraw-white.svg'
import { userContext } from '@context/CurrentUser'
import WithdrawForm from './WithdrawForm'
import Informations from './Informations'

const useStyles = makeStyles((theme) => ({
  root: {
    // padding: '1rem',
    marginTop: 50,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '2rem',
    '& h1': {
      fontSize: '1.5rem',
      color: '#fff',
      margin: '0 0 1rem',
    },
    '& p': {
      color: theme.palette.primary.contrastText,
      margin: '0 0 1rem',
    },
    '& button': {
      minWidth: 170,
    },
  },
}))

const breadcrumbs = [
  { url: '/dashboard', label: <HomeIcon /> },
  { url: '/dashboard', label: 'Dashboard' },
]

const Head = ({ setLastRequest, dashboard, isPendingWithdraw }) => {
  const classes = useStyles()
  const currentUser = useContext(userContext)
  const [openWithdraw, setOpenWithdraw] = useState(false)
  const [openAlert, setOpenAlert] = useState(false)

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <div>
          <h1>Dashboard</h1>
          <p>Selamat Datang di Squids Affiliate {currentUser?.id}</p>
          <Button
            color="primary"
            variant="contained"
            startIcon={<WithdrawIcon />}
            onClick={() => {
              if (isPendingWithdraw) {
                setOpenAlert(true)
              } else {
                setOpenWithdraw(true)
              }
            }}
          >
            Withdraw
          </Button>
        </div>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          {breadcrumbs.map((breadcrumb) => (
            <Link href={breadcrumb.url} key={breadcrumb}>
              {breadcrumb.label}
            </Link>
          ))}
        </Breadcrumbs>
      </div>
      <div className={classes.content}>
        <Informations dashboard={dashboard} />
      </div>
      {openWithdraw && (
        <WithdrawForm
          setOpenWithdraw={setOpenWithdraw}
          dashboard={dashboard}
          setLastRequest={setLastRequest}
        />
      )}
      <Dialog
        open={openAlert}
        onClose={() => {
          setOpenAlert(false)
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Maaf masih ada permintaan withdraw yang tertunda
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Mohon menunggu hingga disetujui
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenAlert(false)} color="primary" autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default Head
