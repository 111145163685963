import React from 'react'
import { Helmet } from 'react-helmet'
import { Container } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import Pattern from '@assets/images/webp/pattern1.webp'
import { ReactComponent as Logo } from '@assets/icons/logo.svg'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100vh',
    maxHeight: '100vh',
    overflow: 'auto',
    background: `url(${Pattern}) no-repeat fixed center`,
    backgroundSize: 'cover',
    backgroundColor: theme.palette.primary.main,
  },
  container: {
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
  },
  box: {
    background: '#fff',
    borderRadius: '20px',
    padding: '4rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  title: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    fontSize: '1.5rem',
    margin: '2rem 0 1rem',
  },
}))

const CenterLayout = ({ children, title, pageTitle }) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Container className={classes.container}>
        <div className={classes.box}>
          <Logo />
          <h1 className={classes.title}>{pageTitle}</h1>
          {children}
        </div>
      </Container>
    </div>
  )
}
export default CenterLayout
