import axios from 'axios'
import Cookies from 'js-cookie'
import { AFFILIATE_API } from '@utils/Environment'
import { token } from '@utils/Authorization'

const loginApi = `${AFFILIATE_API}/oauth/token`
const registerApi = `${AFFILIATE_API}/api/v1/affiliate_users`
const profileApi = `${AFFILIATE_API}/api/v1/profiles`
const passwordApi = `${AFFILIATE_API}/api/v1/affiliate_users/password`

export default {
  async login(params) {
    try {
      const response = await axios.post(loginApi, {
        username: params.email,
        password: params.password,
        scope: 'affiliate',
        grant_type: 'password',
      })
      Cookies.set('affiliate_app_token', response.data?.access_token, { expires: 1 / 12 })
      return response
    } catch (error) {
      return error
    }
  },

  async register(params) {
    try {
      const response = await axios.post(registerApi, {
        email: params.email,
        name: params.name,
        phone_number: params.phone_number,
        code: params.code,
        password: params.password,
        password_confirmation: params.password_confirmation,
      })
      return response.data
    } catch (error) {
      return false
    }
  },

  async forgotPassword(params) {
    try {
      const response = await axios.post(passwordApi, {
        email: params.email,
      })
      return response.data
    } catch (error) {
      return false
    }
  },

  async resetPasswordToken(resetToken) {
    try {
      const response = await axios.get(`${passwordApi}/edit`, {
        params: {
          reset_password_token: resetToken,
        },
      })
      return response.data
    } catch (error) {
      return false
    }
  },

  async updatePassword(resetToken, params) {
    try {
      const response = await axios.put(`${passwordApi}`, {
        reset_password_token: resetToken,
        affiliate_user: {
          password: params.password,
          password_confirmation: params.password_confirmation,
        },
      })
      return response.data
    } catch (error) {
      return false
    }
  },

  async getProfile(id, cookieToken) {
    try {
      const response = await axios.get(`${profileApi}/${id}`, {
        headers: {
          Authorization: `Bearer ${token || cookieToken}`,
        },
      })
      return response.data
    } catch (error) {
      return false
    }
  },

  async updateProfile(params) {
    try {
      const response = await axios.put(`${profileApi}/${params.id}`, {
        affiliate_user: {
          email: params.email,
          name: params.name,
          phone_number: params.phone_number,
          code: params.code,
          bank_name: params.bank_name,
          bank_account: params.bank_account,
          account_name: params.account_name,
        },
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      localStorage.setItem('userData', JSON.stringify(response.data?.data))
      return response.data
    } catch (error) {
      return false
    }
  },
}
