import React, { Fragment, useState } from 'react'
import Form from './Form'
import Result from './Result'

const ForgotPassword = () => {
  const [page, setPage] = useState('form')

  return (
    <Fragment>
      {page === 'form' && <Form setPage={setPage} />}
      {page === 'result' && <Result setPage={setPage} />}
    </Fragment>
  )
}

export default ForgotPassword
