import React, { Fragment, useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import Cookies from 'js-cookie'
import {
  Avatar, Button, Menu, MenuItem, Divider,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { userContext } from '@context/CurrentUser'
import { ReactComponent as EditIcon } from '@assets/images/svg/edit-profile.svg'
import { ReactComponent as LogoutIcon } from '@assets/images/svg/logout.svg'
import EditProfile from './EditProfile'

const useStyles = makeStyles(() => ({
  menu: {
    '& svg': {
      marginRight: '1rem',
    },
  },
}))

const HeaderMenu = () => {
  const classes = useStyles()
  const history = useHistory()
  const [anchorEl, setAnchorEl] = useState(null)
  const [openProfile, setOpenProfile] = useState(false)
  const { user } = useContext(userContext)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const onLogout = () => {
    Cookies.remove('affiliate_app_token')
    localStorage.removeItem('userData')
    history.push('/')
  }

  return (
    <Fragment>
      <Button
        startIcon={<Avatar />}
        onClick={handleClick}
      >
        {user?.name}
      </Button>
      <Menu
        className={classes.menu}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuItem onClick={() => {
          setOpenProfile(true)
          handleClose()
        }}
        >
          <EditIcon /> Edit Profile
        </MenuItem>
        <Divider light />
        <MenuItem onClick={onLogout}>
          <LogoutIcon /> <span style={{ color: '#E82A63' }}>Logout</span>
        </MenuItem>
      </Menu>
      {openProfile && (
        <EditProfile setOpenProfile={setOpenProfile} />
      )}
    </Fragment>

  )
}
export default HeaderMenu
