import React, { Fragment, useState, useEffect } from 'react'
// import { useHistory } from 'react-router-dom'
import queryString from 'query-string'
import { Backdrop, CircularProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import CenterLayout from '@containers/layout/CenterLayout'
import UserApi from '@api/User'
import Landing from './Landing'
import Form from './Form'
import Result from './Result'

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.primary.main,
  },
}))

const ResetPassword = (props) => {
  const classes = useStyles()
  const [page, setPage] = useState('landing')
  const [loading, setLoading] = useState(false)
  const { location } = props
  const params = queryString.parse(location.search)

  useEffect(() => {
    if (params?.reset_password_token) {
      setLoading(true)
      UserApi.resetPasswordToken(params?.reset_password_token)
        .then((res) => {
          if (res) {
            setLoading(false)
          }
        })
    }
  }, [])

  return (
    <Fragment>
      {loading ? (
        <CenterLayout
          pageTitle="Loading"
        >
          <Backdrop className={classes.backdrop} open>
            <CircularProgress color="inherit" />
          </Backdrop>
        </CenterLayout>
      ) : (
        <Fragment>
          {page === 'landing' && <Landing setPage={setPage} />}
          {page === 'form' && <Form setPage={setPage} token={params?.reset_password_token} />}
          {page === 'result' && <Result />}
        </Fragment>
      )}
    </Fragment>
  )
}

export default ResetPassword
