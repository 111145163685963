import Dashboard from '@containers/pages/Dashboard'
import Register from '@containers/pages/auth/Register'
import ForgotPassword from '@containers/pages/auth/ForgotPassword'
import ResetPassword from '@containers/pages/auth/ResetPassword'
import NotFound from '@containers/pages/NotFound'

export const dashboard = Dashboard
export const register = Register
export const notFound = NotFound
export const forgotPassword = ForgotPassword
export const resetPassword = ResetPassword

export default true
