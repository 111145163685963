import React, { Fragment } from 'react'
import { Route, Redirect } from 'react-router-dom'
import Cookies from 'js-cookie'

const PrivateRoute = ({ component: Component, ...rest }) => {
  const isLoggedIn = !!Cookies.get('affiliate_app_token')
  return (
    <Fragment>
      <Route
        {...rest}
        render={
          (props) => (isLoggedIn ? <Component {...props} /> : <Redirect to="/login" />)
        }
      />
    </Fragment>
  )
}

export default PrivateRoute
