import React from 'react'
import {
  AppBar, Container, Toolbar,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import HeaderMenu from './HeaderMenu'
import IconRender from './IconRender'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    padding: '0 2rem',
    height: 90,
  },
  logo: {
    flex: 1,
  },
}))

const Header = () => {
  const classes = useStyles()
  return (
    <AppBar position="fixed">
      <Container maxWidth="xl">
        <Toolbar className={classes.root}>
          <div className={classes.logo}>
            <IconRender icon="logoWhite" />
          </div>
          <HeaderMenu />
        </Toolbar>
      </Container>
    </AppBar>
  )
}
export default Header
