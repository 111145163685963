import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { makeStyles } from '@material-ui/styles'
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'
import UserApi from '@api/User'
import CenterLayout from '@containers/layout/CenterLayout'
import TextPassword from '@components/RHFFields/TextPassword'

const useStyles = makeStyles(() => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    maxWidth: '460px',
  },
  sendButton: {
    marginTop: '1rem',
  },
}))

const resolver = yupResolver(yup.object().shape({
  password: yup.string()
    .required('Tidak boleh kosong')
    .matches(/^(?=.*[a-z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/, 'Password minimal 8 karakter dengan kombinasi angka dan simbol')
    .min(8, 'Password minimal 8 karakter'),
  password_confirmation: yup.string()
    .oneOf([yup.ref('password'), null], 'Password tidak sama'),
}))

const defaultValues = {
  password: '',
  password_confirmation: '',
}

export default function Form({ setPage, token }) {
  const classes = useStyles()
  const history = useHistory()
  const {
    handleSubmit, control, errors,
  } = useForm({ defaultValues, resolver })

  const [loading, setLoading] = useState(false)
  const [openAlert, setOpenAlert] = useState(false)
  const onSubmit = (value) => {
    setLoading(true)
    UserApi.updatePassword(token, value)
      .then((res) => {
        if (res) {
          setPage('result')
        } else {
          setOpenAlert(true)
        }
      })
  }

  return (
    <CenterLayout
      title="Squids Affiliate - Buat Kata Sandi"
      pageTitle="Buat kata sandi baru"
    >
      <p>Buat kata sandi baru minimal 8 karakter yang terdiri dari kombinasi huruf besar, huruf kecil, angka dan simbol.</p>
      <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
        <TextPassword
          name="password"
          label="Kata Sandi Baru"
          control={control}
          errors={errors}
          disabled={loading}
        />
        <TextPassword
          name="password_confirmation"
          label="Konfirmasi Kata Sandi Baru"
          control={control}
          errors={errors}
          disabled={loading}
        />
        <Button
          className={classes.sendButton}
          color="primary"
          variant="contained"
          type="submit"
          disabled={loading}
          fullWidth
        >
          {loading ? <CircularProgress size={24} color="secondary" /> : 'Kirim'}
        </Button>
      </form>
      <Dialog
        open={openAlert}
        onClose={() => history.push('/forgot_password')}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Maaf, token yang kamu pakai sudah kadaluarsa</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Lakukan ulang proses reset kata sandi.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => history.push('/forgot_password')} color="primary" autoFocus>
            Setuju
          </Button>
        </DialogActions>
      </Dialog>
    </CenterLayout>
  )
}
