import axios from 'axios'
import { AFFILIATE_API } from '@utils/Environment'
import { token } from '@utils/Authorization'

const dashboardApi = `${AFFILIATE_API}/api/v1/dashboards`

export default {
  async index(currentToken) {
    try {
      const response = await axios.get(dashboardApi, {
        headers: {
          Authorization: `Bearer ${token || currentToken}`,
        },
      })
      return response.data
    } catch (error) {
      return false
    }
  },
}
