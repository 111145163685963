import React from 'react'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    width: 280,
    height: 130,
    background: theme.palette.primary.main,
    borderRadius: 5,
    maxWidth: '100%',
    padding: '1.5rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'relative',
    marginBottom: '1rem',
    '& h3': {
      color: 'rgba(255, 255, 255, 0.5)',
      fontWeight: 600,
      margin: 0,
      textTransform: 'uppercase',
    },
    '& p': {
      fontWeight: 600,
      fontSize: '1.75rem',
      margin: 0,
      color: '#fff',
      lineHeight: '48px',
    },
    '& svg': {
      position: 'absolute',
      right: '2rem',
    },
    '& img': {
      position: 'absolute',
      right: '2rem',
    },
  },
}))

export default function InfoBox({ title, amount, icon }) {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <h3>{title}</h3>
      <p>{amount}</p>
      {icon}
    </div>
  )
}
