import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { makeStyles } from '@material-ui/styles'
import MailIcon from '@material-ui/icons/MailOutline'
import {
  Button, CircularProgress, InputAdornment,
} from '@material-ui/core'
import CenterLayout from '@containers/layout/CenterLayout'
import Text from '@components/RHFFields/Text'
import UserApi from '@api/User'

const useStyles = makeStyles(() => ({
  form: {
    display: 'flex',
    alignItems: 'flex-start',
    width: '100%',
    '& > div': {
      margin: 0,
    },
  },
  sendButton: {
    width: '120px',
    margin: '1.25rem 0 0 1rem',
  },
}))

const resolver = yupResolver(yup.object().shape({
  email: yup.string().email('Email tidak valid').required('Tidak boleh kosong'),
}))

const defaultValues = {
  email: '',
}

export default function Form({ setPage }) {
  const classes = useStyles()
  const {
    handleSubmit, control, errors, register,
  } = useForm({ defaultValues, resolver })

  const [loading, setLoading] = useState(false)
  const onSubmit = (value) => {
    setLoading(true)
    UserApi.forgotPassword(value)
      .then((res) => {
        if (res) {
          setPage('result')
        }
        setLoading(false)
      })
  }

  return (
    <CenterLayout
      title="Squids Affiliate - Forgot Password"
      pageTitle="Lupa kata sandi?"
    >
      <p>Untuk mengatur ulang kata sandi, silahkan masukkan alamat email Anda.</p>
      <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
        <Text
          name="email"
          label="Email"
          control={control}
          register={register}
          errors={errors}
          InputProps={{
            disabled: loading,
            startAdornment: <InputAdornment position="start"><MailIcon color="primary" /></InputAdornment>,
            fullWidth: true,
          }}
        />
        <Button
          className={classes.sendButton}
          color="primary"
          variant="contained"
          type="submit"
          disabled={loading}
          fullWidth
        >
          {loading ? <CircularProgress size={24} color="secondary" /> : 'Kirim'}
        </Button>
      </form>
    </CenterLayout>
  )
}
