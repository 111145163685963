import React, { Fragment, useState, useEffect } from 'react'
import _ from 'lodash'
import moment from 'moment'
import { makeStyles } from '@material-ui/styles'
import {
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  InputAdornment,
} from '@material-ui/core'
import { Alert, Pagination } from '@material-ui/lab'
import DateFnsUtils from '@date-io/date-fns'
import {
  DatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import WithdrawApi from '@api/Withdraw'
import { thousandFormat } from '@services/Format/Currency'
import { defaultDate } from '@services/Format/Date'
import { ReactComponent as SearchIcon } from '@assets/images/svg/search.svg'

const useStyles = makeStyles(() => ({
  root: {
    padding: '1.5rem 2rem',
    background: '#fff',
    borderRadius: 10,
    marginBottom: '2rem',
    '& p': {
      fontWeight: 600,
      lineHeight: '27px',
    },
  },
  table: {
    marginTop: '0.625rem',
    paddingBottom: '2rem',
  },
  searchField: {
    '& .MuiInputBase-root': {
      padding: '0.375rem 0.75rem',
      border: '1px solid #C4C4C4',
      borderRadius: 5,
    },
    '& .MuiInputAdornment-positionStart': {
      paddingBottom: 0,
    },
    '& .MuiInputBase-input': {
      height: 'auto',
      padding: 0,
    },
  },
  dateField: {
    '& .MuiInputBase-root': {
      padding: '0.375rem 0.75rem',
      border: '1px solid #C4C4C4',
      borderRadius: 5,
      width: 170,
      marginRight: '1rem',
    },
    '& .MuiInputBase-input': {
      height: 'auto',
      padding: 0,
    },
  },
  filterForm: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    marginBottom: '1rem',
  },
  loading: {
    padding: '1rem',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  resetButton: {
    fontSize: '0.875rem',
    height: 38,
  },
}))

const TableWithdraw = ({ lastRequest, token, setIsPendingWithdraw }) => {
  const classes = useStyles()
  const [loading, setLoading] = useState(true)
  const [withdraws, setWithdraws] = useState()
  const [bankName, setBankName] = useState()
  const [page, setPage] = useState(1)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)

  const fetchWithdraw = () => {
    setLoading(true)
    WithdrawApi.index(
      token,
      page,
      bankName,
      {
        startDate: startDate ? moment(startDate).format('YYYY-MM-DD') : '',
        endDate: endDate ? moment(endDate).format('YYYY-MM-DD') : '',
      },
    )
      .then((res) => {
        setWithdraws(res)
        setIsPendingWithdraw(res.data.filter((withdraw) => withdraw.status === 'Pending').length > 0)
        setLoading(false)
      })
  }

  const resetFilter = () => {
    setStartDate(null)
    setEndDate(null)
    setBankName()
  }

  useEffect(() => {
    fetchWithdraw()
  }, [page, lastRequest, bankName, startDate, endDate])

  return (
    <div className={classes.root}>
      <p>Data Withdraw Request</p>
      <div className={classes.filterForm}>
        <TextField
          placeholder="Cari bank"
          onChange={(e) => setBankName(e.target.value)}
          className={classes.searchField}
          InputProps={{
            startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
          }}
          variant="outlined"
        />
        <div>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              value={startDate}
              placeholder="Dari tanggal"
              format="dd/MM/yyyy"
              onChange={setStartDate}
              className={classes.dateField}
            />
            <DatePicker
              value={endDate}
              placeholder="Sampai tanggal"
              minDate={startDate || new Date()}
              format="dd/MM/yyyy"
              onChange={setEndDate}
              className={classes.dateField}
            />
            <Button
              color="primary"
              variant="contained"
              className={classes.resetButton}
              onClick={() => resetFilter()}
            >
              Reset
            </Button>
          </MuiPickersUtilsProvider>
        </div>
      </div>
      {!loading ? (
        <Fragment>
          {withdraws && withdraws.data?.length > 0 ? (
            <Fragment>
              <TableContainer className={classes.table}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Tanggal Request</TableCell>
                      <TableCell>No Rekening</TableCell>
                      <TableCell>Nama Pemilik</TableCell>
                      <TableCell>Bank</TableCell>
                      <TableCell>Tanggal Disetujui</TableCell>
                      <TableCell>Tanggal Ditransfer</TableCell>
                      <TableCell>Nominal Withdraw</TableCell>
                      <TableCell>Biaya Admin</TableCell>
                      <TableCell>Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {withdraws.data.map((withdraw) => (
                      <TableRow key={withdraw.id}>
                        <TableCell>{defaultDate(withdraw.created_at)}</TableCell>
                        <TableCell>{withdraw.bank_account}</TableCell>
                        <TableCell>{withdraw.account_name}</TableCell>
                        <TableCell>{withdraw.bank_name}</TableCell>
                        <TableCell>{defaultDate(withdraw.approved_at)}</TableCell>
                        <TableCell>{defaultDate(withdraw.transfered_at)}</TableCell>
                        <TableCell>Rp. {thousandFormat(`${parseInt(withdraw.amount, 10)}`)}</TableCell>
                        <TableCell>Rp. {thousandFormat(`${parseInt(withdraw.admin_fee, 10)}`)}</TableCell>
                        <TableCell>{_.upperCase(withdraw.status || 'pending')}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Pagination
                count={withdraws.pagination.total_pages}
                page={page}
                onChange={(_event, value) => setPage(value)}
                shape="rounded"
              />
            </Fragment>
          ) : (
            <Alert variant="outlined" severity="warning">
              Data yang di cari masih kosong / tidak ditemukan
            </Alert>
          )}
        </Fragment>
      ) : (
        <div className={classes.loading}>
          <CircularProgress />
        </div>
      )}
    </div>
  )
}

export default TableWithdraw
