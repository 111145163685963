import React from 'react'
import { Button } from '@material-ui/core'
import CenterLayout from '@containers/layout/CenterLayout'

export default function Result() {
  return (
    <CenterLayout
      title="Squids Affiliate - Atur Ulang Kata Sandi"
      pageTitle="Kata sandi berhasil diubah!"
    >
      <p>Kami telah berhasil mengatur ulang kata sandi Anda, silahkan masuk menggunakan kata sandi yang baru saja Anda buat.</p>
      <Button
        href="/"
        style={{ margin: '1rem 0 0' }}
        color="primary"
        variant="contained"
      >
        Kembali ke Halaman Login
      </Button>
    </CenterLayout>
  )
}
