import { Box } from '@material-ui/core'
import React from 'react'
import { Route } from 'react-router-dom'

const NotFound = () => (
  <Route
    render={({ staticContext }) => {
      if (staticContext) {
        staticContext.status = 404; // eslint-disable-line
      }
      return (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
          height="100%"
          position="fixed"
        >
          404 | Not Found
        </Box>
      )
    }}
  />
)

export default NotFound
