import React, { Fragment, useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { IconButton, Slide, Snackbar } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { ReactComponent as CopyIcon } from '@assets/images/svg/copy-white.svg'

const useStyles = makeStyles((theme) => ({
  root: {
    width: 475,
    height: 85,
    borderRadius: 5,
    maxWidth: '100%',
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    position: 'relative',
    marginBottom: '1rem',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    '& h3': {
      color: '#fff',
      fontWeight: 600,
      fontSize: '0.875rem',
      margin: 0,
    },
    '& p': {
      color: '#fff',
      fontWeight: 600,
      margin: 0,
      lineHeight: '27px',
      [theme.breakpoints.down('sm')]: {
        fontSize: 10,
      },
    },
    '& h1': {
      color: '#fff',
      fontWeight: 600,
      fontSize: '1.5rem',
      margin: 0,
      lineHeight: '40px',
    },
  },
  icon: {
    height: '100%',
    flex: '0 0 128px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '0 0.75rem',
    '& button': {
      position: 'absolute',
      right: '1rem',
      top: '1rem',
      padding: 0,
    },
  },
}))

export default function InfoPanel(props) {
  const {
    title,
    amount,
    text,
    icon,
    backgroundPrimary,
    backgroundSecondary,
  } = props
  const classes = useStyles()
  const [copied, setCopied] = useState(false)
  return (
    <div className={classes.root} style={{ background: backgroundPrimary }}>
      <div className={classes.icon} style={{ background: backgroundSecondary }}>
        {icon}
      </div>
      <div className={classes.content}>
        <h3>{title}</h3>
        {amount && <h1>{amount}</h1>}
        {text && <p>{text}</p>}
        {text && (
          <Fragment>
            <CopyToClipboard text={text}>
              <IconButton onClick={() => setCopied(true)}>
                <CopyIcon />
              </IconButton>
            </CopyToClipboard>
            <Snackbar
              open={copied}
              onClose={() => setCopied(false)}
              TransitionComponent={Slide}
              message="Berhasil dicopy"
            />
          </Fragment>
        )}
      </div>
    </div>
  )
}
