import React from 'react'
import { Link, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import CenterLayout from '@containers/layout/CenterLayout'

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: '1.5rem',
    fontWeight: 600,
    lineHeight: '40px',
  },
  subtitle: {
    textAlign: 'center',
  },
  resend: {
    fontSize: '0.75rem',
    marginTop: '1.5rem',
    '& a': {
      fontWeight: 500,
      color: theme.palette.primary.main,
      textDecoration: 'none',
    },
  },
}))

export default function Result({ values }) {
  const squidsAffiliateLink = <Link href="#!">Squids Affiliate</Link>
  const classes = useStyles()
  return (
    <CenterLayout
      title="Squids Affiliate - Pendaftaran Berhasil"
      pageTitle="Pendaftaran Berhasil"
    >
      <h1 className={classes.title}>Selamat Menggunakan {squidsAffiliateLink}, {values.name}!</h1>
      <p className={classes.subtitle}>
        Mohon periksa inbox di <b>{values.email}</b><br />dan lakukan verifikasi Email untuk melanjutkan.
      </p>
      <Button
        href="/"
        style={{ margin: '1rem 0 0' }}
        color="primary"
        variant="contained"
      >
        Lanjut ke Login
      </Button>
      {/* <p className={classes.resend}>
        Tidak menerima Email? <Link href="#!">Kirim ulang</Link>
      </p> */}
    </CenterLayout>
  )
}
