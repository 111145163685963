import React, { Fragment, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import Cookies from 'js-cookie'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    position: 'fixed',
    width: '100%',
    height: '100%',
  },
  contentLeft: {
    display: 'flex',
    width: '40%',
    padding: '1.5rem',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      padding: theme.spacing(1),
    },
  },
  contentRight: {
    display: 'flex',
    width: '60%',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
    position: 'relative',
    overflow: 'hidden',
    '& img': {
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translateX(-50%)translateY(-50%)',
      width: '100%',
    },
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
}))

const AuthContainer = ({ children, childrenRight, title }) => {
  const classes = useStyles()
  const history = useHistory()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const isLoggedIn = !!Cookies.get('affiliate_app_token')
    if (isLoggedIn) {
      history.push('/dashboard')
    } else {
      setLoading(false)
    }
  }, [])

  return (
    <div className={classes.root}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {!loading && (
        <Fragment>
          <div className={classes.contentLeft}>
            {children}
          </div>
          <div className={classes.contentRight}>
            {childrenRight}
          </div>
        </Fragment>
      )}
    </div>
  )
}

export default AuthContainer
