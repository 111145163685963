import {
  Box, IconButton, InputAdornment, TextField, Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React, { useState } from 'react'
import { Controller } from 'react-hook-form'
import LockIcon from '@material-ui/icons/LockOutlined'
import VisibilityIcon from '@material-ui/icons/VisibilityOutlined'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOffOutlined'

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: 12,
    fontWeight: 500,
    color: theme.palette.primary.textGrey,
  },
  box: {
    margin: '0 0 1rem',
    width: '100%',
  },
  underline: {
    borderBottom: '#c7c7c7',
  },
  input: {
    padding: '1rem 0 0 0.625rem',
    borderBottom: '1px solid #C4C4C4',
  },
}))

const TextPassword = ({
  control, name, label, errors, disabled, noStartIcon,
}) => {
  const classes = useStyles()
  const [showPassword, setShowPassword] = useState(false)
  return (
    <Controller
      control={control}
      name={name}
      render={({ ref, onChange, onBlur }) => (
        <Box
          className={classes.box}
          display="flex"
          flexDirection="column"
        >
          {label && <Typography className={classes.label}>{label}</Typography>}
          <TextField
            className={classes.input}
            InputProps={{
              disabled,
              startAdornment: !noStartIcon && <InputAdornment position="start"><LockIcon color="primary" /></InputAdornment>,
              endAdornment:
  <InputAdornment position="end">
    <IconButton onClick={() => setShowPassword(!showPassword)}>
      {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
    </IconButton>
  </InputAdornment>,
            }}
            type={showPassword ? 'text' : 'password'}
            inputRef={ref}
            onChange={onChange}
            onBlur={onBlur}
          />
          {!!errors[name]?.message && (
            <p className="error-text">{errors[name]?.message}</p>
          )}
        </Box>
      )}
    />
  )
}
export default TextPassword
