import React from 'react'
import { Link } from 'react-router-dom'
import AuthContainer from '@containers/layout/AuthContainer'
import Background from '@assets/images/webp/background-login.webp'
import { makeStyles } from '@material-ui/styles'
import LoginForm from './LoginForm'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  link: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
  forgotPassword: {
    fontSize: '0.75rem',
    fontWeight: 500,
    color: theme.palette.primary.main,
    textDecoration: 'none',
  },
  registerLink: {
    fontSize: '0.75rem',
    marginTop: '1.5rem',
    '& a': {
      fontWeight: 500,
      color: theme.palette.primary.main,
      textDecoration: 'none',
    },
  },
}))

const Login = () => {
  const classes = useStyles()
  return (
    <AuthContainer
      childrenRight={<img src={Background} alt="man" style={{ zIndex: 1 }} />}
      title="Squids Affiliate - Login"
    >
      <div className={classes.root}>
        <LoginForm />
        <Link
          className={classes.forgotPassword}
          to="/forgot_password"
        >
          Lupa Kata Sandi?
        </Link>
        <p className={classes.registerLink}>
          Anda Belum Memiliki Akun? <Link to="/register">Daftar</Link>
        </p>
      </div>
    </AuthContainer>
  )
}
export default Login
