import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { ReactComponent as Terms } from '@assets/images/svg/terms.svg'
import { ReactComponent as Tips } from '@assets/images/svg/tips.svg'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '0 2rem',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: 0,
    },
  },
  content: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      width: '100%',
    },
  },
  box: {
    width: 'calc(50% - 5rem)',
    background: '#fff',
    padding: '2rem',
    borderRadius: 10,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: 0,
    },
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '1.5rem',
    '& svg': {
      marginRight: '1rem',
    },
    '& p': {
      fontWeight: 600,
    },
  },
  list: {
    listStyle: 'none',
    padding: '0 0 0 1.5rem',
    margin: 0,
    position: 'relative',
    '& li': {
      lineHeight: '24px',
      marginBottom: '0.5rem',
      '&::before': {
        content: '""',
        position: 'absolute',
        left: 0,
        width: '0.5rem',
        height: '0.5rem',
        background: theme.palette.primary.main,
        marginTop: '8px',
        borderRadius: '50%',
      },
    },
  },
}))

const Body = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <div className={classes.box}>
          <div className={classes.title}>
            <Terms />
            <p>Syarat dan Ketentuan Program</p>
          </div>
          <ul className={classes.list}>
            <li>Anda akan mendapatkan komisi setiap pembelian dari Link Afiliasi Anda.</li>
            <li>Untuk berpromosi, Anda dapat menggunakan REFERRAL URL diatas atau dengan menggunakan REFERRAL CODE pada saat melakukan pendaftaran.</li>
            <li>Anda dapat melihat siapa saja yang mendaftar dengan Link Afiliasi Anda di halaman ini, termasuk statusnya sudah melakukan pembayaran atau belum.</li>
            <li>Anda dapat menarik komisi apabila ada minimal 1 orang Afiliasi Anda yang sudah melakukan pembayaran, tidak ada ketentuan waktu dan tanggal untuk melakukan panarikan komisi.</li>
          </ul>
        </div>
        <div className={classes.box}>
          <div className={classes.title}>
            <Tips />
            <p>Tips Berpromosi</p>
          </div>
          <ul className={classes.list}>
            <li>Membuat review tentang SQUIDS AFFILIATE baik di Blog / Website atau social media Anda. Pelanggan baru akan tertarik membeli jika direkomendasikan orang lain atau dari membaca review orang lain.</li>
            <li>Bagikan Link Afiliasi ke social media dengan membuat review singkat dan rekomendasi terlebih dahulu.</li>
            <li>Anda juga dapat membagikan Link Afiliasi melalui aplikasi perpesanan, seperti Whatsapp, LINE, Telegram, atau lainnya.</li>
            <li>Jika Anda punya Email Subscriber, Anda juga dapat mengirimkan email kepada mereka.</li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Body
