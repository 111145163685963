import React, {
  Fragment, useState, useContext, useEffect,
} from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { makeStyles } from '@material-ui/styles'
import { Button, CircularProgress, InputAdornment } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import Modal from '@components/Modal'
import Text from '@components/RHFFields/Text'
import { userContext } from '@context/CurrentUser'
import { thousandFormat } from '@services/Format/Currency'
import WithdrawApi from '@api/Withdraw'
import { ReactComponent as Withdraw } from '@assets/images/svg/withdraw.svg'
import { ReactComponent as Statistic } from '@assets/images/svg/statistic.svg'
import { ReactComponent as MoneyIcon } from '@assets/images/svg/money-primary.svg'
import { ReactComponent as SafeBoxIcon } from '@assets/images/svg/safe-box-primary.svg'
import { ReactComponent as CalculationIcon } from '@assets/images/svg/calculation-primary.svg'
import { ReactComponent as SuccessIcon } from '@assets/images/svg/success.svg'
import SelecFromApi from '@components/RHFFields/SelecFromApi'

const useStyles = makeStyles(() => ({
  form: {
    display: 'flex',
    alignItems: 'flex-end',
    flexWrap: 'wrap',
    width: 'calc(100% + 1rem)',
    margin: '0 -1rem',
    '& > div': {
      flex: '0 0 calc(50% - 2rem)',
      margin: '1.5rem 1rem 0',
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  headerLeft: {
    display: 'flex',
    alignItems: 'center',
    '& h1': {
      fontSize: '1.25rem',
    },
    '& svg': {
      marginRight: '1rem',
    },
  },
  headerRight: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    '& > p': {
      fontWeight: 600,
    },
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  button: {
    width: 'calc(50% - 1rem)',
  },
  result: {
    textAlign: 'center',
    fontWeight: 600,
    width: 400,
    '& p': {
      marginBottom: 0,
    },
  },
}))

const resolver = yupResolver(
  yup.object().shape({
    amount: yup.string().required('Tidak boleh kosong'),
    bank: yup.object().required('Tidak boleh kosong'),
    bank_account: yup.string().required('Tidak boleh kosong'),
    account_name: yup.string().required('Tidak boleh kosong'),
  }),
)

export default function WithdrawForm({
  setOpenWithdraw,
  setLastRequest,
  dashboard,
}) {
  const classes = useStyles()
  const { user } = useContext(userContext)
  const {
    handleSubmit,
    control,
    errors,
    register,
    watch,
    getValues,
    setValue,
  } = useForm({ resolver })

  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState('form')
  const [alert, setAlert] = useState()
  const values = getValues()
  const currentSaldo = parseInt(dashboard?.current_balance, 10)
  const [listBank, setListBank] = useState([])

  useEffect(() => {
    WithdrawApi.listBank().then((res) => {
      setListBank(res?.data)
    })
  }, [])

  useEffect(() => {
    if (parseInt(values?.amount, 10) + 2775 >= currentSaldo) {
      setValue('amount', currentSaldo - 2775)
    }
  }, [values?.amount, currentSaldo])

  const onSubmit = (value) => {
    setAlert(null)
    setLoading(true)
    WithdrawApi.request(value)
      .then((res) => {
        if (Array.isArray(res.data)) {
          throw new Error(res.data[0])
        } else {
          setPage('result')
          setLastRequest(new Date())
          setTimeout(() => {
            setOpenWithdraw(false)
          }, 1000)
        }
      })
      .catch((err) => {
        const error = err?.response?.data?.data || err
        if (Array.isArray(error)) {
          setAlert(error[0])
          setLoading(false)
        } else {
          setAlert(error || 'Nominal withdraw tidak bisa melebih saldo')
          setLoading(false)
          // eslint-disable-next-line no-console
          console.log(err)
        }
      })
  }

  return (
    <Modal>
      {page === 'form' && (
        <Fragment>
          <div className={classes.header}>
            <div className={classes.headerLeft}>
              <Withdraw />
              <h1>Withdraw</h1>
            </div>
            <div className={classes.headerRight}>
              <Statistic />
              <p>Total Nominal Rp.{thousandFormat(watch('amount') ? parseInt(watch('amount'), 10) : 0)}</p>
            </div>
          </div>
          <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
            <div>
              <Text
                name="amount"
                label="Nominal Withdraw"
                type="number"
                control={control}
                value={values?.amount}
                register={register}
                errors={errors}
                currency
                inputProps={{
                  min: 0,
                  max: currentSaldo - 2775,
                }}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                InputProps={{
                  disabled: loading,
                  startAdornment: (
                    <InputAdornment position="start">
                      <MoneyIcon />
                    </InputAdornment>
                  ),
                  fullWidth: true,
                }}
              />
            </div>
            <div>
              <Text
                name="bank_account"
                label="Nomor Rekening"
                control={control}
                register={register}
                errors={errors}
                defaultValue={user?.bank_account}
                InputProps={{
                  disabled: loading,
                  startAdornment: (
                    <InputAdornment position="start">
                      <SafeBoxIcon />
                    </InputAdornment>
                  ),
                  fullWidth: true,
                }}
              />
            </div>
            <div>
              <SelecFromApi
                name="bank"
                label="Pilih Bank"
                placeholder="Cari Bank..."
                register={register}
                setValue={setValue}
                errors={errors}
                control={control}
                getOptionLabel={(value) => `${value.name}`}
                options={listBank || []}
              />
            </div>
            <div>
              <Text
                name="account_name"
                label="Nama Pemilik Rekening"
                control={control}
                register={register}
                errors={errors}
                defaultValue={user?.account_name}
                InputProps={{
                  disabled: loading,
                  startAdornment: (
                    <InputAdornment position="start">
                      <CalculationIcon />
                    </InputAdornment>
                  ),
                  fullWidth: true,
                }}
              />
            </div>
            <div />
            <div className={classes.actions}>
              <Button
                className={classes.button}
                variant="contained"
                onClick={() => setOpenWithdraw(false)}
              >
                Batal
              </Button>
              <Button
                className={classes.button}
                color="primary"
                variant="contained"
                type="submit"
                disabled={loading}
              >
                {loading ? (
                  <CircularProgress size={24} color="secondary" />
                ) : (
                  'Kirim'
                )}
              </Button>
            </div>
          </form>
        </Fragment>
      )}
      {page === 'result' && (
        <div className={classes.result}>
          <SuccessIcon />
          <p>Data withdraw berhasil dikirim.</p>
        </div>
      )}

      {alert && (
        <Alert style={{ marginTop: 16 }} variant="outlined" severity="warning">
          {alert}
        </Alert>
      )}
    </Modal>
  )
}
