import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { makeStyles } from '@material-ui/styles'
import {
  Box, Button, CircularProgress, InputAdornment, Typography,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import MailIcon from '@material-ui/icons/MailOutline'
import { yupResolver } from '@hookform/resolvers/yup'
import TextPassword from '@components/RHFFields/TextPassword'
import IconRender from '@components/IconRender'
import Text from '@components/RHFFields/Text'
import UserApi from '@api/User'

const useStyles = makeStyles((theme) => ({
  box: {
    width: '100%',
  },
  alert: {
    marginBottom: '1rem',
    width: '100%',
    maxWidth: 600,
  },
  form: {
    direction: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: 600,
  },
  title: {
    fontSize: 20,
    fontWeight: 600,
    lineHeight: '34px',
    marginBottom: '2rem',
    '&::after': {
      content: '""',
      display: 'block',
      borderBottom: `2px solid ${theme.palette.primary.main}`,
      width: 64,
      margin: '0.5rem auto 0',
    },
  },
  logo: {
    marginBottom: theme.spacing(2),
    height: '100%',
  },
  button: {
    margin: '1rem 0 1.5rem',
  },
}))

const resolver = yupResolver(yup.object().shape({
  email: yup.string().email('Email tidak valid').required('Tidak boleh kosong'),
  password: yup.string().required('Tidak boleh kosong'),
}))

const defaultValues = {
  email: '',
  password: '',
}

const LoginForm = () => {
  const classes = useStyles()
  const {
    handleSubmit, control, errors, register,
  } = useForm({ defaultValues, resolver })

  const [loading, setLoading] = useState(false)
  const [alert, setAlert] = useState(null)
  const history = useHistory()

  const onSubmit = (value) => {
    setLoading(true)
    UserApi.login(value)
      .then((res) => {
        setAlert(null)
        if (res.status === 200) {
          history.push('/dashboard')
        } else if (res?.response.data) {
          setAlert(res?.response?.data?.error || res?.response?.data?.messages)
        }
        setLoading(false)
      }).catch((e) => {
        if (e?.response) {
          setAlert(e?.response?.error || e?.response?.messages)
        }
      })
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      className={classes.box}
    >
      <IconRender icon="logo" className={classes.logo} />
      <Typography className={classes.title}>
        Masuk ke Dashboard
      </Typography>
      {alert && (
        <Alert onClose={() => setAlert(false)} severity="error" className={classes.alert}>
          {alert}
        </Alert>
      )}
      <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
        <Text
          name="email"
          label="Email"
          control={control}
          register={register}
          errors={errors}
          InputProps={{
            disabled: loading,
            startAdornment: <InputAdornment position="start"><MailIcon color="primary" /></InputAdornment>,
            fullWidth: true,
          }}
        />
        <TextPassword
          name="password"
          label="Password"
          control={control}
          errors={errors}
          disabled={loading}
        />
        <Button
          className={classes.button}
          color="primary"
          variant="contained"
          type="submit"
          disabled={loading}
          fullWidth
        >
          {loading ? <CircularProgress size={24} color="secondary" /> : 'Masuk'}
        </Button>
      </form>
    </Box>
  )
}
export default LoginForm
