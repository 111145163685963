import React, {
  Fragment, useState, useEffect, useContext,
} from 'react'
import DashboardApi from '@api/Dashboard'
import { userContext } from '@context/CurrentUser'
import { Container } from '@material-ui/core'
import Head from './Head'
import Body from './Body'
import Footer from './Footer'

const Dashboard = () => {
  const { token } = useContext(userContext)
  const [lastRequest, setLastRequest] = useState(new Date())
  const [dashboard, setDashboard] = useState()
  const [isPendingWithdraw, setIsPendingWithdraw] = useState(false)

  const fetchDashboard = () => {
    DashboardApi.index(token)
      .then((res) => {
        setDashboard(res.data)
      })
  }

  useEffect(() => {
    fetchDashboard()
  }, [])

  return (
    <Fragment>
      <Container>
        <Head lastRequest={lastRequest} setLastRequest={setLastRequest} dashboard={dashboard} isPendingWithdraw={isPendingWithdraw} />
        <Body lastRequest={lastRequest} setLastRequest={setLastRequest} setIsPendingWithdraw={setIsPendingWithdraw} />
        <Footer lastRequest={lastRequest} setLastRequest={setLastRequest} />
      </Container>
    </Fragment>
  )
}

export default Dashboard
