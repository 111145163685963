import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { makeStyles } from '@material-ui/styles'
import {
  Box, Button, CircularProgress,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { yupResolver } from '@hookform/resolvers/yup'
import Text from '@components/RHFFields/Text'
import TextPassword from '@components/RHFFields/TextPassword'
import UserApi from '@api/User'

const useStyles = makeStyles((theme) => ({
  alert: {
    marginBottom: '1rem',
    width: '100%',
  },
  form: {
    direction: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  title: {
    fontSize: '1.25rem',
    lineHeight: '36px',
    fontWeight: 600,
    alignSelf: 'flex-start',
    margin: 0,
  },
  description: {
    color: theme.palette.primary.textGrey,
    lineHeight: '24px',
    margin: '0 0 2rem',
    '&::after': {
      content: '""',
      display: 'block',
      borderBottom: `2px solid ${theme.palette.primary.main}`,
      width: 64,
      margin: '0.5rem 0 0',
    },
  },
  inputs: {
    justifyContent: 'space-between',
    '& > div': {
      width: 'calc(50% - 1rem)',
    },
  },
  button: {
    marginTop: '1rem',
  },
}))

const resolver = yupResolver(yup.object().shape({
  name: yup.string().required('Tidak boleh kosong'),
  email: yup.string().email('Email tidak valid').required('Tidak boleh kosong'),
  password: yup.string()
    .required('Tidak boleh kosong')
    .matches(/^(?=.*[a-z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/, 'Password minimal 8 karakter dengan kombinasi angka dan simbol')
    .min(8, 'Password minimal 8 karakter'),
  password_confirmation: yup.string()
    .oneOf([yup.ref('password'), null], 'Password tidak sama'),
  code: yup.string()
    .required('Tidak boleh kosong')
    .min(5, 'Kode Referral minimal 5 karakter'),
}))

const defaultValues = {
  name: '',
  code: '',
  phone_number: '+62',
  email: '',
  password: '',
  password_confirmation: '',
}

const RegisterForm = ({ setPage, setValues }) => {
  const classes = useStyles()
  const [alert, setAlert] = useState(false)
  const [loading, setLoading] = useState(false)

  const {
    handleSubmit, control, errors, register,
  } = useForm({ defaultValues, resolver })

  const onSubmit = (value) => {
    setLoading(true)
    UserApi.register(value)
      .then((res) => {
        setAlert(!res)
        if (res) {
          setValues(value)
          setPage('result')
        }
        setLoading(false)
      })
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      width="70%"
    >
      <p className={classes.title}>Selamat Datang</p>
      <p className={classes.description}>
        Ayo kita siapkan semuanya sehingga Anda dapat mulai menyiapkan kampanye pertama Anda!
      </p>
      {alert && (
        <Alert onClose={() => setAlert(false)} severity="error" className={classes.alert}>
          Email atau Password salah!
        </Alert>
      )}
      <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
        <input type="hidden" name="phone_number" ref={register} />
        <Text
          name="name"
          label="Nama Lengkap"
          control={control}
          register={register}
          errors={errors}
          InputProps={{
            disabled: loading,
            fullWidth: true,
          }}
        />
        <Text
          name="email"
          label="Email"
          control={control}
          register={register}
          errors={errors}
          InputProps={{
            disabled: loading,
            fullWidth: true,
          }}
        />
        <Box display="flex" className={classes.inputs}>
          <TextPassword
            name="password"
            label="Kata Sandi"
            control={control}
            register={register}
            errors={errors}
            noStartIcon
            InputProps={{
              disabled: loading,
              fullWidth: true,
            }}
          />
          <TextPassword
            name="password_confirmation"
            label="Konfirmasi Kata Sandi"
            control={control}
            register={register}
            errors={errors}
            noStartIcon
            InputProps={{
              disabled: loading,
              fullWidth: true,
            }}
          />
        </Box>
        <Text
          name="code"
          label="Kode Referral"
          control={control}
          register={register}
          errors={errors}
          InputProps={{
            disabled: loading,
            fullWidth: true,
          }}
        />
        <Button
          className={classes.button}
          color="primary"
          variant="contained"
          type="submit"
          disabled={loading}
          fullWidth
        >
          {loading ? <CircularProgress size={24} color="secondary" /> : 'Daftar Sekarang'}
        </Button>
      </form>
    </Box>
  )
}
export default RegisterForm
