import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Cookies from 'js-cookie'
import { Container } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { userContext } from '@context/CurrentUser'
import Header from '@components/Header'
import UserApi from '@api/User'
import Pattern from '@assets/images/webp/pattern2.webp'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    background: '#F3F3F3',
    paddingBottom: '4rem',
  },
  space: {
    backgroundColor: '#676767',
    position: 'absolute',
    top: 0,
    width: '100%',
    height: 400,
  },
  patternImg: {
    position: 'absolute',
    top: 100,
    width: '100%',
  },
  content: {
    marginTop: 90,
    zIndex: 1,
    minHeight: 'calc(100vh - 90px)',
    position: 'relative',
    '&::before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: 260,
      background: `url(${Pattern})`,
      backgroundSize: '',
      zIndex: -1,
    },
  },
}))

const DashboardLayout = ({ children }) => {
  const classes = useStyles()
  const history = useHistory()
  const [currentUser, setCurrentUser] = useState(JSON.parse(localStorage.getItem('userData')))
  const token = Cookies.get('affiliate_app_token')

  const onLogout = () => {
    Cookies.remove('affiliate_app_token')
    localStorage.removeItem('userData')
    history.push('/')
  }

  const fetchCurrentUser = () => {
    UserApi.getProfile(0, token)
      .then((res) => {
        if (res) {
          setCurrentUser(res.data)
        } else {
          onLogout()
        }
      })
  }

  useEffect(() => {
    fetchCurrentUser()
  }, [])

  return (
    <userContext.Provider value={{ token, user: currentUser, fetchCurrentUser }}>
      <Header />
      <div className={classes.root}>
        <Container maxWidth="xl" className={classes.content}>
          {children}
        </Container>
      </div>
    </userContext.Provider>
  )
}
export default DashboardLayout
