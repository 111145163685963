import React from 'react'
import { Controller } from 'react-hook-form'
import {
  Box, TextField, Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: 12,
    fontWeight: 500,
    color: theme.palette.primary.textGrey,
  },
  box: {
    margin: '0 0 1rem',
    width: '100%',
  },
  input: {
    padding: '1rem 0 0 0.625rem',
    borderBottom: '1px solid #C4C4C4',
  },
}))

const Text = ({
  control, name, InputProps, label, placeholder, errors, defaultValue, type, inputProps, ...props
}) => {
  const classes = useStyles()
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ ref, onChange, onBlur }) => (
        <Box
          className={classes.box}
          display="flex"
          flexDirection="column"
        >
          {label && <Typography className={classes.label}>{label}</Typography>}
          <TextField
            className={classes.input}
            defaultValue={defaultValue}
            InputProps={InputProps}
            // eslint-disable-next-line react/jsx-no-duplicate-props
            inputProps={inputProps}
            inputRef={ref}
            onChange={onChange}
            onBlur={onBlur}
            placeholder={placeholder}
            type={type || 'text'}
            {...props}
          />
          {!!errors[name]?.message && (
            <p className="error-text">{errors[name]?.message}</p>
          )}
        </Box>
      )}
    />
  )
}

export default Text
