import React from 'react'
import { makeStyles } from '@material-ui/styles'
import InfoBox from '@components/InfoBox'
import InfoPanel from '@components/InfoPanel'
import { thousandFormat } from '@services/Format/Currency'
import ProfitsIcon from '@assets/images/png/profits.png'
import MoneyIcon from '@assets/images/png/money.png'
import WithdrawalIcon from '@assets/images/png/withdrawal.png'
import { ReactComponent as BrowserIcon } from '@assets/images/svg/browser-white.svg'
import { ReactComponent as CurrentAmount } from '@assets/images/svg/current_amount.svg'
import { ReactComponent as ReferralUrl } from '@assets/images/svg/referral_url.svg'
import { ReactComponent as MyReferral } from '@assets/images/svg/my_referral.svg'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
    '& > div:not(:last-child)': {
      marginRight: '1rem',
    },
  },
}))

export default function Informations({ dashboard }) {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <div style={{ flex: 1 }}>
        <InfoBox
          title="Total Registrasi"
          amount={dashboard?.total_registration || 0}
          icon={<BrowserIcon />}
        />
        <InfoBox
          title="Total Pendapatan"
          amount={`Rp. ${thousandFormat(parseInt(dashboard?.total_income, 10)) || 0}`}
          icon={<img src={ProfitsIcon} alt="profits" />}
        />
      </div>
      <div style={{ flex: 1 }}>
        <InfoBox
          title="Total Transaksi Referral"
          amount={dashboard?.total_referral || 0}
          icon={<img src={MoneyIcon} alt="profits" />}
        />
        <InfoBox
          title="Total Withdrawal"
          amount={`Rp. ${thousandFormat(parseInt(dashboard?.total_withdrawn, 10)) || 0}`}
          icon={<img src={WithdrawalIcon} alt="profits" />}
        />
      </div>
      <div style={{ flex: 1 }}>
        <InfoPanel
          title="Saldo Saat Ini"
          amount={`Rp. ${thousandFormat(parseInt(dashboard?.current_balance, 10)) || 0}`}
          backgroundPrimary="#37FF60"
          backgroundSecondary="#00FF34"
          icon={<CurrentAmount />}
        />
        <InfoPanel
          title="Referral URL"
          text={dashboard?.link || '-'}
          backgroundPrimary="#FFAD38"
          backgroundSecondary="#FF9600"
          icon={<ReferralUrl />}
        />
        <InfoPanel
          title="Kode Referral Saya"
          text={dashboard?.referral_code || '-'}
          backgroundPrimary="#37AFFF"
          backgroundSecondary="#0099FF"
          icon={<MyReferral />}
        />
      </div>
    </div>
  )
}
